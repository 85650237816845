<template>
  <div class="box">
    <!-- <div class="top-text">
      <strong>企业认证</strong>
    </div> -->
    <el-form ref="form" :model="form" label-width="150px" class="box-form">
      <div class="row">
        <div class="box-input">
          <el-form-item label="企业名称">
            <el-input v-model="form.enterpriseName"></el-input>
          </el-form-item>
          <el-form-item label="社会信用代码">
            <el-input v-model="form.code"></el-input>
          </el-form-item>
          <el-form-item label="法人代表">
            <el-input v-model="form.representative"></el-input>
          </el-form-item>
          <el-form-item label="工商注册号">
            <el-input v-model="form.businessNum"></el-input>
          </el-form-item>
          <el-form-item label="所属行业">
            <el-select
              v-model="form.region"
              placeholder="请选择所属行业"
              class="box-input"
            >
              <el-option label="行业一" value="shanghai"></el-option>
              <el-option label="行业二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属领域">
            <el-select
              v-model="form.region"
              placeholder="请选择所属领域"
              class="box-input"
            >
              <el-option label="领域一" value="shanghai"></el-option>
              <el-option label="领域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标签">
            <el-checkbox-group v-model="form.type">
              <el-checkbox label="互联网" name="type"></el-checkbox>
              <el-checkbox label="物联网" name="type"></el-checkbox>
              <el-checkbox label="工业" name="type"></el-checkbox>
              <el-checkbox label="农业" name="type"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="联系人姓名">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="联系人电话">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="应用场景描述">
            <el-input type="textarea" v-model="form.desc"></el-input>
          </el-form-item>
        </div>
        <div>
          <el-upload
            action="https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
          <p>仅支持JPEG 2m以内的图片</p>
        </div>
      </div>
      <div class="row">
        <div>
          <el-form-item>
            <el-button @click.native="$router.push('/console/accountinfo')"
              >取消</el-button
            >
            <el-button type="primary" @click="onSubmit">申请认证</el-button>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
      dialogImageUrl: '',
      dialogVisible: false,
    };
  },
  methods: {
    onSubmit() {
      this.$router.push('/console/accountinfo').catch((e) => e);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped>
.box {
  width: 100%;
}
.top-text {
  margin: 16px 0;
}
.box-input {
  width: 56%;
}
.pic {
  width: 160px;
  height: 160px;
}
.box-form {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
}
.row {
  flex-basis: 100%;
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.row:nth-child(2) {
  justify-content: center;
}
</style>
